import * as React from 'react'
import Page from '../components/Page'
import Container from '../components/Container'
import { useLocation } from '@reach/router';
import { getStaticPageContent } from '../services/api';
import { useState } from 'react';
import styled from 'styled-components';

// @ts-ignore
import ThumbsIcon from '../resources/icons/thumbs.svg';
import PageHeader from '../components/PageHeader'
import { breakpoints, colors, fonts } from '../styles/variables'

const StyledContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    margin-top: 0px;
  }
`

const Content = styled.div`
  width: 50%;
  margin: 5em auto 5em auto;
  overflow: hidden;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 80%;
    margin: 5em auto 5em auto;
  }
`

const Image = styled.img`
  width: 100%;
  height: 367px;
  justify-content: center;
  align-content: center;
  object-fit: fill;
  overflow: hidden;
  @media ${`(max-width: ${breakpoints.md}px)`} {
    height: 270px;
  }
`

const ContentContainer = styled.div`
  color: ${colors.black};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;

`

const TextContainer = styled.div`
  font-family: 'Source Code Pro', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
  overflow-wrap: break-word;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    font-size: 14px;
    line-height: 18px;
  }
`

const ContentPage = (props: any) => {
  const location = useLocation();
  const [apiResult, setApiResult] = useState(null);
  const [apiFetched, setApiFetched] = useState(false);

  const [htmlContent, setHtmlContent] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [pageImage, setPageImage] = useState(null);


  if (location.pathname && !apiResult) {

    const contentPageName = props['*'];
    console.log(contentPageName);

    getStaticPageContent(contentPageName).then((data) => {
      console.log(data.data);
      setHtmlContent(data.data.content);
      setPageTitle(data.data.title);
      setPageImage(data.data.imageSrc);
      setApiResult(data.data);
      setApiFetched(true);
    })
  } else {
    // Something went wrong, no params?
  }
  return (
    <Page>
      <StyledContainer>
        <PageHeader title={pageTitle} />
        <Content>
          {pageImage &&
            <Image src={pageImage ?? undefined} />
          }
          <ContentContainer>
            <TextContainer dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </ContentContainer>
        </Content>
      </StyledContainer>
    </Page>
  )
}

export default ContentPage
